import React from "react";

import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js";
import "./src/styles/global.scss";
import { GatsbyBrowser } from "gatsby";
import { RootProvider } from "./src/contexts/root.context";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => <RootProvider>{element}</RootProvider>;

// Fix issues with pages not scrolling to the top after loading.
export const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location);

  setTimeout(() => {
    if (typeof window !== "undefined") {
      if (location.hash) window.location.hash = location.hash;
      else window.scrollTo(...(currentPosition || [0, 0]));
    }
  }, 0);

  return false;
};
