export const enum AgeGateCookieEnum {
  CookieName = "ageCheck",
  ConsentAllValue = "a",
  ConsentReqValue = "r",
  CookieDuration = 3600 * 24 * 30, // 30 days, in seconds
}

export const enum ThemeModeEnum {
  auto = "auto",
  dark = "dark",
  light = "light",
}
